@font-face {
  font-family: 'KAWAIITEGAKIMOJI';
  src: url('fonts/KTEGAKI.woff') format('woff');
}
    
.text1 {
  font-family:'KAWAIITEGAKIMOJI';
  font-size:18px;
  letter-spacing: 3px;
  text-align: justify;
  text-justify: inter-ideograph;
}

/* 本文 */
p {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 10px;
  letter-spacing: 3px;
  text-align: justify;
  text-justify: inter-ideograph;
  color:#111111;
}

/* キャプション*/
p.title{
  font-size: 14px;
  font-weight: bold;
  color:#111111;
  padding-bottom: 0px;
}

form {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 10px;
  letter-spacing: 3px;
  text-align: justify;
  text-justify: inter-ideograph;
  color:#111111;
}

#like {
  font-size: 24px;
}